"use client";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import React, { Key, useState } from "react";
import * as menuData from "./menu-data";
import Icon from "@/components/primitives/icon";
import Button from "../primitives/button";
import Card from "../primitives/card";
import Feedback from "./feedback";
import { useSportsbooks } from "@/utils/providers/sportsbook";
import { useIsTouchDevice } from "@/utils/hooks/device";

interface MegaMenuProps {
  sportsbookMenuItems: menuData.MenuItem[];
  legalStatesMenuItems: LegalStateMenuItem[];
}

export interface LegalStateMenuItem extends menuData.MenuItem {
  legal: boolean;
}

function MegaMenu(props: MegaMenuProps) {
  const isTouchDevice = useIsTouchDevice();
  const viewportRef = React.useRef(null);
  const [menu, setMenu] = useState<string>("");

  const legalStatesMenuItems = props.legalStatesMenuItems.filter(
    (state) => state.legal
  );

  const illegalStatesMenuItems = props.legalStatesMenuItems.filter(
    (state) => !state.legal
  );

const menuItems = menuData.menuItems();
  return (
    <>
      <NavigationMenu.Root
        onValueChange={(value) => setMenu(value)}
        value={menu}
        className="hidden desktop-menu:flex justify-center"
      >
        <NavigationMenu.List className="flex list-none bg-secondary-100 h-20">
          {menuItems.map((menuItem, i) => (
            <NavigationMenu.Item key={i} value={menuItem.id}>
              <NavigationMenu.Trigger
                asChild
                onClick={!isTouchDevice ? (e) => e.preventDefault() : undefined}
              >
                <Button
                  data-state={menu === menuItem.id ? "active" : undefined}
                  variant="tile"
                  className="select-none outline-none text-light !text-title-lg-bold hidden lg:flex px-4"
                >
                  {menuItem.label}
                </Button>
              </NavigationMenu.Trigger>
              <div className="relative">
                <NavigationMenu.Content
                  onPointerEnter={(e) => e.preventDefault()}
                  onInteractOutside={(e) => e.preventDefault()}
                  className="group/parent absolute top-0 left-0 w-[70.375rem]"
                >
                  <div
                    className={`relative bg-[#f9fafa] transition-colors duration-[50ms] ease-in-out`}
                  >
                    <Card.Trim
                      fromColor="rgba(0, 182, 240, 0.40)"
                      toColor="rgba(51, 255, 0, 0.40)"
                    ></Card.Trim>
                    <div
                      id="menu-transition-container"
                      className="group-data-[motion=from-start]/parent:animate-enterFromLeft group-data-[motion=from-end]/parent:animate-enterFromRight group-data-[motion=to-start]/parent:animate-exitToLeft group-data-[motion=to-end]/parent:animate-exitToRight"
                    >
                      <NavigationMenu.Sub
                        defaultValue={menuItem.children?.[0].id}
                        orientation="vertical"
                      >
                        <div className="flex">
                          <NavigationMenu.List
                            className={`list-none flex flex-col  bg-surface pl-10 pr-8 pb-7 pt-8 w-[17rem] min-h-[26rem]`}
                            // style={{ width: `${menuItem.viewportWidth}rem` }}
                          >
                            <div className="flex flex-col gap-0">
                              <h4 className="text-label-bold text-secondary-500 uppercase mb-4">
                                {menuItem.heading}
                              </h4>
                              <div className="flex flex-row flex-wrap">
                                {menuItem.children?.map((subMenuItem, i) => (
                                  <NavigationMenu.Item
                                    key={i}
                                    value={subMenuItem.id}
                                    className="w-full"
                                  >
                                    {subMenuItem.slug ? (
                                      <Link
                                        slug={subMenuItem.slug}
                                        icon={subMenuItem.icon}
                                        label={subMenuItem.label}
                                        logo={subMenuItem.logo}
                                        caption={subMenuItem.caption}
                                      />
                                    ) : (
                                      <Trigger
                                        caption={subMenuItem.caption}
                                        icon={subMenuItem.icon}
                                        label={subMenuItem.label}
                                        logo={subMenuItem.logo}
                                      />
                                    )}
                                    <NavigationMenu.Content
                                      onFocusOutside={(e) => e.preventDefault()}
                                      className={`data-[motion=from-start]:animate-enterFromLeft data-[motion=from-end]:animate-enterFromRight data-[motion=to-start]:animate-exitToLeft data-[motion=to-end]:animate-exitToRight absolute w-full h-full sm:w-auto duration-200`}
                                    >
                                      <div className="flex flex-col w-full px-8 pt-8 pb-12 h-full overflow-y-auto overflow-x-hidden">
                                        <h4 className="text-label-bold text-secondary-500 uppercase mb-4">
                                          {subMenuItem.heading ||
                                            menuItem.label}
                                        </h4>
                                        {subMenuItem.id === "legal-states" ? (
                                          <>
                                            <ul
                                              className={`one m-0 flex flex-row flex-wrap list-none gap-x-4 gap-y-2 ${
                                                legalStatesMenuItems &&
                                                legalStatesMenuItems.length < 4
                                                  ? "max-w-[700px]"
                                                  : "max-w-none"
                                              }`}
                                            >
                                              {legalStatesMenuItems?.map(
                                                (item, i) => (
                                                  <li key={i}>
                                                    {item.slug ? (
                                                      <Link
                                                        slug={item.slug}
                                                        label={item.label}
                                                        slim
                                                      />
                                                    ) : (
                                                      <>
                                                        <div className="flex w-[8.5rem] text-title-sm-medium py-2">
                                                          {item.label}
                                                        </div>
                                                      </>
                                                    )}
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                            <h4 className="text-label-bold text-secondary-500 uppercase mb-4 mt-8">
                                              Not Legal States
                                            </h4>
                                            <ul
                                              className={`one m-0 flex flex-row flex-wrap list-none gap-x-4 gap-y-2 ${
                                                illegalStatesMenuItems &&
                                                illegalStatesMenuItems.filter(
                                                  (state) => state.legal
                                                ).length < 4
                                                  ? "max-w-[700px]"
                                                  : "max-w-none"
                                              }`}
                                            >
                                              {illegalStatesMenuItems?.map(
                                                (item, i) => (
                                                  <li key={i}>
                                                    {item.slug ? (
                                                      <Link
                                                        slug={item.slug}
                                                        label={item.label}
                                                        slim
                                                      />
                                                    ) : (
                                                      <>
                                                        <div className="flex w-[8.5rem] text-title-sm-medium py-2">
                                                          {item.label}
                                                        </div>
                                                      </>
                                                    )}
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                          </>
                                        ) : subMenuItem.id ===
                                          "all-sportsbooks" ? (
                                          <ul
                                            className={`one m-0 flex flex-row flex-wrap list-none gap-x-4 gap-y-2 ${
                                              props.sportsbookMenuItems &&
                                              props.sportsbookMenuItems.length <
                                                4
                                                ? "max-w-[700px]"
                                                : "max-w-none"
                                            }`}
                                          >
                                            {props.sportsbookMenuItems?.map(sportsbookMenuItem => (
                                                <li key={i}>
                                                  {sportsbookMenuItem?.slug ? (
                                                    <Link
                                                      slug={
                                                        sportsbookMenuItem?.slug
                                                      }
                                                      label={
                                                        sportsbookMenuItem?.label
                                                      }
                                                      logo={
                                                        sportsbookMenuItem?.logo
                                                      }
                                                    />
                                                  ) : (
                                                    <>
                                                      <div className="flex w-[8.5rem] text-title-sm-medium py-2">
                                                        {
                                                          sportsbookMenuItem.label
                                                        }
                                                      </div>
                                                    </>
                                                  )}
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        ) : (
                                          <ul
                                            className={`one m-0 flex flex-row flex-wrap list-none gap-x-4 gap-y-2 ${
                                              subMenuItem.children &&
                                              subMenuItem.children.length < 4
                                                ? "max-w-[700px]"
                                                : "max-w-none"
                                            }`}
                                          >
                                            {subMenuItem.children?.map(
                                              (subMenuItemChild, i) => (
                                                <li key={i}>
                                                  {subMenuItemChild.slug ? (
                                                    <Link
                                                      disabled={
                                                        subMenuItemChild.disabled
                                                      }
                                                      slug={
                                                        subMenuItemChild.slug
                                                      }
                                                      icon={
                                                        subMenuItemChild.icon
                                                      }
                                                      label={
                                                        subMenuItemChild.label
                                                      }
                                                      logo={
                                                        subMenuItemChild.logo &&
                                                        `/logos/${subMenuItemChild.logo}.svg`
                                                      }
                                                      caption={
                                                        subMenuItemChild.caption
                                                      }
                                                    />
                                                  ) : (
                                                    <>
                                                      <div className="flex w-[8.5rem] text-title-sm-medium py-2">
                                                        {subMenuItemChild.label}
                                                      </div>
                                                    </>
                                                  )}
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        )}
                                      </div>
                                    </NavigationMenu.Content>
                                  </NavigationMenu.Item>
                                ))}
                                <NavigationMenu.Item className="w-full">

                                  <Feedback color="light" />
                                </NavigationMenu.Item>
                              </div>
                            </div>
                          </NavigationMenu.List>
                          <NavigationMenu.Viewport
                            className={`relative overflow-hidden w-full`}
                          />
                        </div>
                      </NavigationMenu.Sub>
                    </div>
                  </div>
                </NavigationMenu.Content>
              </div>
            </NavigationMenu.Item>
          ))}
        </NavigationMenu.List>

        <div
          data-state={menu ? "open" : "closed"}
          ref={viewportRef}
          className={`perspective-[2000px] absolute top-full w-full left-0 justify-center flex data-[state=open]:animate-fadeIn data-[state=closed]:animate-fadeOut`}
        >
          <NavigationMenu.Viewport
            className={`shadow-elevation-400 data-[state=open]:animate-scaleIn data-[state=closed]:animate-scaleOut relative h-[var(--radix-navigation-menu-viewport-height)] origin-[top_center] overflow-hidden rounded-md bg-surface transition-[width,_height] duration-300 w-[var(--radix-navigation-menu-viewport-width)]`}
          />
        </div>
      </NavigationMenu.Root>
    </>
  );
}

interface MegaMenuTriggerProps {
  label: string;
  logo?: string;
  icon?: string;
  caption?: string;
}
function Trigger(props: MegaMenuTriggerProps) {
  return (
    <NavigationMenu.Trigger asChild>
      <Button
        variant="standard"
        custom
        className="group rounded-md text-left justify-between px-4 py-2 text-secondary-300 hover:text-secondary-300 data-[state=open]:text-secondary-300 text-title-sm-medium fill-secondary-500 hover:bg-surface-400 data-[state=open]:bg-surface-400 active:bg-secondary-700 w-full min-w-[11.125rem]"
      >
        <div className="flex flex-row">
          {props.icon ? (
            <Icon
              name={props.icon}
              size="md"
              className={`text-secondary group-hover:text-dark group-hover:fill-secondary-300 group-data-[state=open]:fill-secondary-300 m-2`}
            ></Icon>
          ) : (
            props.logo && (
              <img src={`/logos/${props.logo}.svg`} className="m-2" />
            )
          )}
          <div className="inline-flex justify-center flex-col gap-1">
            <span>{props.label}</span>
          </div>
        </div>
        <Icon
          name="arrow-right"
          size="sm"
          className="group-hover:fill-secondary-300 group-data-[state=open]:fill-secondary-300 m-2 fill-transparent"
        ></Icon>
      </Button>
    </NavigationMenu.Trigger>
  );
}

interface MegaMenuLinkProps {
  disabled?: boolean;
  label: string;
  slug: string;
  caption?: string;
  icon?: string;
  logo?: string;
  slim?: boolean;
}
function Link(props: MegaMenuLinkProps) {
  return (
    <NavigationMenu.Link asChild>
      <Button
        custom
        variant="standard"
        href={props.slug}
        className={`normal-case ${
          props.slim ? "py-2 px-4" : "p-4"
        } gap-3 justify-start text-left fill-primary-500 flex items-center rounded-md hover:bg-surface-400 active:bg-secondary-700 w-full ${
          props.disabled ? "pointer-events-none" : ""
        }`}
      >
        {props.icon ? (
          <Icon variant="circle" name={props.icon} color="primary" size="md" />
        ) : (
          props.logo && (
            <img
              src={props.logo}
              className=" w-8 h-8 object-contain object-center"
            />
          )
        )}
        <div
          className={`flex flex-col gap-1 ${
            props.logo ? "min-w-[6.5rem]" : "min-w-[10.18rem]"
            // "min-w-[6.5rem]"
          }`}
        >
          <span className="text-title-sm-medium text-dark">{props.label}</span>
          {props.caption && (
            <span className="text-label-lg text-secondary-500">
              {props.caption}
            </span>
          )}
        </div>
      </Button>
    </NavigationMenu.Link>
  );
}

MegaMenu.Trigger = Trigger;
MegaMenu.Link = Link;
export default MegaMenu;
