"use client";
import React from "react";
import { Sling as Hamburger } from "hamburger-react";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import Icon from "@/components/primitives/icon";
import * as menuData from "./menu-data";
import Link from "next/link";
import Button, { BUTTON_VARIANCE } from "../primitives/button";
import { LegalStateMenuItem } from "./mega-menu";
import Feedback from "./feedback";
import { useMenu } from "@/utils/providers/menu";

interface MobileMenuProps {
  legalStatesMenuItems: LegalStateMenuItem[];
}

function MobileMenu({ legalStatesMenuItems }: MobileMenuProps) {
  const menuItems = menuData.menuItems(true);
  // const [open, setOpen] = React.useState(false);
  const {mobileMenuOpen, setMobileMenuOpen, mobileProfileMenuOpen, setMobileProfileMenuOpen} = useMenu();
  const [menuTier0, setMenuTier0] = React.useState<string>("");
  const [menuTier1, setMenuTier1] = React.useState<string>("");
  const [menuTier2, setMenuTier2] = React.useState<string>("");
  const [menuTier3, setMenuTier3] = React.useState<string>("");

  function changeMenu(value: string, tier: number) {
    if (tier === 3) {
      if (value) {
        setMenuTier3(value);
      } else {
        resetMenu();
      }
    } else if (tier === 2) {
      if (value) {
        setMenuTier2(value);
      } else {
        resetMenu();
      }
    } else if (tier === 1) {
      if (value) {
        setMenuTier1(value);
      } else {
        resetMenu();
      }
    } else if (tier === 0) {
      setMenuTier0(value);
      if (value) {
        setMobileMenuOpen(true);
      } else {
        setMobileMenuOpen(false);
      }
    }
  }

  function resetMenu() {
    setMenuTier3("");
    setMenuTier2("");
    setMenuTier1("");
    setMenuTier0("");
  }

  // When a menu changes and the drawer is closed, reset the menus.
  React.useEffect(() => {
    if (!mobileMenuOpen) {
      resetMenu();
    }
    if (mobileMenuOpen && mobileProfileMenuOpen) {
      setMobileProfileMenuOpen(false);
    }
  }, [menuTier0, menuTier1, menuTier2, menuTier3]);

  const legalStatesItems = legalStatesMenuItems.filter((state) => state.legal);

  const illegalStatesItems = legalStatesMenuItems.filter(
    (state) => !state.legal
  );

  return (
    <>
      <NavigationMenu.Root
        orientation="vertical"
        className="block desktop-menu:hidden"
        onValueChange={(value) => changeMenu(value, 0)}
        value={menuTier0}
      >
        <NavigationMenu.List>
          <NavigationMenu.Item
            value={"main"}
            className="flex justify-center items-center"
          >
            <NavigationMenu.Trigger
              onPointerLeave={(e) => e.preventDefault()}
              onPointerMove={(e) => e.preventDefault()}
              data-state={mobileMenuOpen ? "open" : "closed"}
            >
              <Hamburger
                toggled={mobileMenuOpen}
                toggle={setMobileMenuOpen}
                color="#fff"
                size={28}
                rounded={true}
              />
            </NavigationMenu.Trigger>

            <NavigationMenu.Content
              onPointerDownOutside={(e) => e.preventDefault()}
            >
              <div
                className={`absolute w-full h-full pt-4 transition-all duration-150 ease-in-out ${
                  menuTier2
                    ? "-translate-x-[200%]"
                    : menuTier1
                    ? "-translate-x-full"
                    : "translate-x-0"
                }`}
              >
                <NavigationMenu.Sub
                  orientation="vertical"
                  value={menuTier1}
                  onValueChange={(value) => changeMenu(value, 1)}
                >
                  <NavigationMenu.List>
                    {menuItems.map((menuItem, i) => (
                      <NavigationMenu.Item value={menuItem.id} key={i}>
                        <MobileTrigger label={menuItem.label} />
                        <NavigationMenu.Content className="pt-2">
                          <MobileNavigateBack
                            label={menuItem.label}
                            navigateBack={() => setMenuTier1("")}
                          />

                          <NavigationMenu.Sub
                            orientation="vertical"
                            value={menuTier2}
                            onValueChange={(value) => changeMenu(value, 2)}
                          >
                            <NavigationMenu.List>
                              {/* Second map for weird nesting from mega menu */}
                              {menuItem.children?.map((menuItemTier2, i) => (
                                <div key={i}>
                                  {menuItemTier2.slug &&
                                    i === 0 &&
                                    menuItemTier2.children && (
                                      <div key={i}>
                                        {menuItemTier2.children.map(
                                          (menuItemTier2Child, i) => (
                                            <div key={i}>
                                              {menuItemTier2Child.slug && (
                                                <NavigationMenu.Item key={i}>
                                                  <MobileLink
                                                    disabled={
                                                      menuItemTier2Child.disabled
                                                    }
                                                    slug={
                                                      menuItemTier2Child.slug
                                                    }
                                                    icon={
                                                      menuItemTier2Child.icon
                                                    }
                                                    label={
                                                      menuItemTier2Child.label
                                                    }
                                                    logo={
                                                      menuItemTier2Child.logo
                                                    }
                                                    caption={
                                                      menuItemTier2Child.caption
                                                    }
                                                  />
                                                </NavigationMenu.Item>
                                              )}
                                            </div>
                                          )
                                        )}
                                      </div>
                                    )}
                                </div>
                              ))}
                              {menuItem.children?.map((menuItemTier2, i) => (
                                <NavigationMenu.Item
                                  value={menuItemTier2.id}
                                  key={i}
                                >
                                  {menuItemTier2.slug ? (
                                    <MobileLink
                                      disabled={menuItemTier2.disabled}
                                      slug={menuItemTier2.slug}
                                      icon={menuItemTier2.icon}
                                      label={menuItemTier2.label}
                                      logo={menuItemTier2.logo}
                                      caption={menuItemTier2.caption}
                                    />
                                  ) : (
                                    <MobileTrigger
                                      icon={menuItemTier2.icon}
                                      label={menuItemTier2.label}
                                      logo={menuItemTier2.logo}
                                    />
                                  )}

                                  <NavigationMenu.Content className="py-4">
                                    <MobileNavigateBack
                                      label={menuItemTier2.label}
                                      navigateBack={() => setMenuTier2("")}
                                    />
                                    <NavigationMenu.Sub
                                      orientation="vertical"
                                      value={menuTier3}
                                      onValueChange={(value) =>
                                        changeMenu(value, 3)
                                      }
                                    >
                                      <NavigationMenu.List>
                                        {menuItemTier2.id === "legal-states" ? (
                                          <>
                                            {legalStatesItems.map(
                                              (stateItem, i) => (
                                                <NavigationMenu.Item
                                                  value={stateItem.id}
                                                  key={i}
                                                >
                                                  <MobileLink
                                                    disabled={
                                                      stateItem.disabled
                                                    }
                                                    slug={stateItem.slug!}
                                                    icon={stateItem.icon}
                                                    label={stateItem.label}
                                                    logo={stateItem.logo}
                                                    caption={stateItem.caption}
                                                  />
                                                </NavigationMenu.Item>
                                              )
                                            )}
                                            <div className="text-headline-lg font-bold uppercase text-dark p-4 pt-8">
                                              Not Legal States
                                            </div>
                                            {illegalStatesItems.map(
                                              (stateItem, i) => (
                                                <NavigationMenu.Item
                                                  value={stateItem.id}
                                                  key={i}
                                                >
                                                  <MobileLink
                                                    disabled={
                                                      stateItem.disabled
                                                    }
                                                    slug={stateItem.slug!}
                                                    icon={stateItem.icon}
                                                    label={stateItem.label}
                                                    logo={stateItem.logo}
                                                    caption={stateItem.caption}
                                                  />
                                                </NavigationMenu.Item>
                                              )
                                            )}
                                          </>
                                        ) : (
                                          menuItemTier2.children?.map(
                                            (menuItemTier3, i) => (
                                              <NavigationMenu.Item
                                                value={menuItemTier3.id}
                                                key={i}
                                              >
                                                {menuItemTier3.slug ? (
                                                  <MobileLink
                                                    disabled={
                                                      menuItemTier3.disabled
                                                    }
                                                    slug={menuItemTier3.slug}
                                                    icon={menuItemTier3.icon}
                                                    label={menuItemTier3.label}
                                                    logo={menuItemTier3.logo}
                                                    caption={
                                                      menuItemTier3.caption
                                                    }
                                                  />
                                                ) : (
                                                  <MobileTrigger
                                                    icon={menuItemTier3.icon}
                                                    label={menuItemTier3.label}
                                                    logo={menuItemTier3.logo}
                                                  />
                                                )}

                                                <NavigationMenu.Content>
                                                  <MobileNavigateBack
                                                    label={menuTier2}
                                                    navigateBack={() =>
                                                      setMenuTier3("")
                                                    }
                                                  />
                                                </NavigationMenu.Content>
                                              </NavigationMenu.Item>
                                            )
                                          )
                                        )}
                                      </NavigationMenu.List>
                                      <NavigationMenu.Viewport
                                        data-state={
                                          menuTier3 ? "open" : "closed"
                                        }
                                        onPointerEnter={(event) =>
                                          event.preventDefault()
                                        }
                                        onPointerLeave={(event) =>
                                          event.preventDefault()
                                        }
                                        onPointerMove={(event) =>
                                          event.preventDefault()
                                        }
                                        className="w-full h-full absolute top-0 left-full"
                                      />
                                    </NavigationMenu.Sub>
                                  </NavigationMenu.Content>
                                </NavigationMenu.Item>
                              ))}
                            </NavigationMenu.List>
                            <NavigationMenu.Viewport
                              data-state={menuTier2 ? "open" : "closed"}
                              onPointerEnter={(event) => event.preventDefault()}
                              onPointerLeave={(event) => event.preventDefault()}
                              onPointerMove={(event) => event.preventDefault()}
                              className="w-full h-full absolute top-0 left-full"
                            />
                          </NavigationMenu.Sub>
                        </NavigationMenu.Content>
                      </NavigationMenu.Item>
                    ))}
                  </NavigationMenu.List>
                  <div className="mt-4 mb-8 bg-secondary-700 w-full h-px"></div>
                    <div className="px-4">

                    <Feedback color="dark" />
                    </div>

                  <NavigationMenu.Viewport
                    data-state={menuTier1 ? "open" : "closed"}
                    onPointerEnter={(event) => event.preventDefault()}
                    onPointerLeave={(event) => event.preventDefault()}
                    onPointerMove={(event) => event.preventDefault()}
                    className="w-full h-full absolute top-0 left-full"
                  />
                </NavigationMenu.Sub>
              </div>
            </NavigationMenu.Content>
          </NavigationMenu.Item>
        </NavigationMenu.List>

        <NavigationMenu.Viewport
          forceMount={true}
          data-state={mobileMenuOpen ? "open" : "closed"}
          onPointerEnter={(event) => event.preventDefault()}
          onPointerLeave={(event) => event.preventDefault()}
          onPointerMove={(event) => event.preventDefault()}
          className={`z-40 fixed left-0 shadow-elevation-400 bg-surface w-full h-[calc(100%-var(--navbar-height))] -translate-x-full data-[state=open]:translate-x-0 transition-transform duration-150 ease-in-out overflow-x-hidden top-[var(--navbar-height)] overflow-y-auto bg-[url('/images/mobile-bg-pattern.png')] bg-contain bg-top bg-no-repeat`}
        ></NavigationMenu.Viewport>
      </NavigationMenu.Root>
    </>
  );
}

interface MobileLinkProps {
  disabled?: boolean;
  slug: string;
  label: string;
  icon?: string;
  logo?: string;
  caption?: string;
}

function MobileLink(props: MobileLinkProps) {
  return (
    <NavigationMenu.Link asChild>
      <Link
        href={props.slug}
        prefetch={false}
        className={`flex flex-row whitespace-nowrap items-center transition-all duration-200 ease-in-out disabled:pointer-events-none justify-between text-center relative text-title-lg-bold uppercase px-4 py-5 gap-2 rounded-none w-full hover:bg-surface-600 active:bg-surface-500`}
      >
        <div className="flex flex-row gap-4 items-center">
          {props.icon && (
            <Icon
              variant="circle"
              name={props.icon}
              size="lg"
              color="primary"
            />
          )}
          {props.logo && <img src={`/logos/${props.logo}.svg`} />}
          <div className="flex flex-col gap-1 justify-start items-start">
            <span className="text-headline-medium normal-case text-dark">
              {props.label}
            </span>
            {props.caption && (
              <span className="text-title-lg normal-case text-secondary">
                {props.caption}
              </span>
            )}
          </div>
        </div>
      </Link>
    </NavigationMenu.Link>
  );
}

interface MobileTriggerProps {
  label: string;
  icon?: string;
  logo?: string;
}

function MobileTrigger(props: MobileTriggerProps) {
  return (
    <NavigationMenu.Trigger
      onPointerLeave={(e) => e.preventDefault()}
      onPointerMove={(e) => e.preventDefault()}
      className={`flex flex-row whitespace-nowrap items-center transition-all duration-200 ease-in-out disabled:pointer-events-none justify-between text-center relative uppercase gap-2 rounded-none w-full text-dark text-headline-lg py-5 px-5 hover:bg-surface-600 active:bg-surface-500`}
    >
      <div className="flex flex-row gap-4 items-center">
        {props.icon && (
          <Icon variant="circle" name={props.icon} size="md" color="primary" />
        )}
        {props.logo && <img src={`/logos/${props.logo}.svg`} className="p-3" />}
        <span>{props.label}</span>
      </div>
      <Icon name="caret-right" size="md" className="fill-dark" />
    </NavigationMenu.Trigger>
  );
}

interface MobileNavigateBackProps {
  label: string;
  navigateBack: () => void;
}
function MobileNavigateBack(props: MobileNavigateBackProps) {
  return (
    <div className="flex flex-row gap-4 items-center ml-4 py-4">
      <Button
        variant="circle"
        size="md"
        color="tertiary"
        onClick={props.navigateBack}
      >
        <Icon name="arrow-left" size="sm"></Icon>
      </Button>
      <span className="text-headline-lg font-bold uppercase text-dark">
        {props.label}
      </span>
    </div>
  );
}

export default MobileMenu;
