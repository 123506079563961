import(/* webpackMode: "eager" */ "/root/repo/client/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["FooterNewsletter"] */ "/root/repo/client/src/components/global/newsletter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/repo/client/src/components/layout/feedback.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/repo/client/src/components/layout/header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/repo/client/src/components/pick/create-pick.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/repo/client/src/components/primitives/button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BettorProvider"] */ "/root/repo/client/src/utils/providers/bettor.tsx");
