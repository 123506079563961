import React from "react";

interface AvatarProps {
  image?: string;
  size?: "xs" | "md";
}
export default function Avatar(props: AvatarProps) {
  return (
    <div
      className={`rounded-full ${
        props.size === "xs" ? "w-6 h-6" : "w-8 h-8"
      } bg-surface-500 overflow-hidden`}
    >
      <img
        src={props.image}
        alt=""
        className="w-full h-full object-cover object-center"
      />
    </div>
  );
}
