"use client";
import React from "react";
import Navbar from "./navbar";


export default function Header({sportsbooks, legalStates}: {sportsbooks: any, legalStates: any}) {
  return (
    <>
      <header className="pb-[var(--navbar-height)]">
        <Navbar
          sportsbookMenuItems={!sportsbooks.error ? sportsbooks : []}
          legalStatesMenuItems={!legalStates.error ? legalStates : []}
        />
      </header>
    </>
  );
}