import mlClient from "@/ml-client";
import { BettorProfileModel } from "common";

export function buildProfileRequest(username: string, clerk?: boolean): string {
  return `${process.env.NEXT_PUBLIC_API_URL}/bettors${clerk ? "/clerk" : ""}/${clerk ? username : username.toLowerCase()}/profile`;
}

export async function getBettorProfile(type: "username" | "clerk_id", value: string, token?: string): Promise<BettorProfileModel> {
  const result = await mlClient.get(`bettors/${type}/${value}/profile`, token);
  return result.data;
}

export async function deactivateSelf(token: string): Promise<boolean> {
  const result = await mlClient.post(`bettors/self-management`, {action: "deactivate"}, token);
  return result.data;
}
export async function resetSelfStats(token: string): Promise<boolean> {
  const result = await mlClient.post(`bettors/self-management`, {action: "reset-stats"}, token);
  return result.data;
}
