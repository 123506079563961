"use client";
import React, { createContext, useContext, useState, useEffect } from "react";


interface MenuContextType {
  mobileMenuOpen: boolean;
  setMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  mobileProfileMenuOpen: boolean;
  setMobileProfileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  megaMenuOpen: boolean;
  setMegaMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MenuContext = createContext<MenuContextType>({
  mobileMenuOpen: false,
  setMobileMenuOpen: () => {},
  mobileProfileMenuOpen: false,
  setMobileProfileMenuOpen: () => {},
  megaMenuOpen: false,
  setMegaMenuOpen: () => {},
});

export const useMenu = () => {
  const context = useContext(MenuContext);
  if (!context) {
    throw new Error("useMenu must be used within a MenuProvider");
  }
  return context;
};

export function MenuProvider({ children }: { children: React.ReactNode }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const [mobileProfileMenuOpen, setMobileProfileMenuOpen] =
    useState<boolean>(false);
  const [megaMenuOpen, setMegaMenuOpen] = useState<boolean>(false);

  React.useEffect(() => {
    if (mobileMenuOpen || mobileProfileMenuOpen) {
      document.body.classList.add("remove-scroll");
    } else {
      document.body.classList.remove("remove-scroll");
    }

  }, [mobileMenuOpen, mobileProfileMenuOpen]);


  return (
    <MenuContext.Provider
      value={{
        mobileMenuOpen,
        setMobileMenuOpen,
        mobileProfileMenuOpen,
        setMobileProfileMenuOpen,
        megaMenuOpen,
        setMegaMenuOpen,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
}
