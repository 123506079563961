import { COMING_SOON_SPORTS, SPORTS } from "@/data/sports/options";
import { LEGAL_STATES } from "@/data/legal";
import {
  createInjuriesSlug,
  createScoresSlug,
  createStandingsSlug,
  createStatsSlug,
} from "@/utils/url";
import { Sport, getDefaultYear } from "common";

export interface MenuItem {
  attributes?: any;
  id?: string;
  slug?: string;
  disabled?: boolean;
  icon?: string;
  logo?: string;
  // The heading for groups of items
  heading?: string;
  // The label of the individual item
  label: string;
  caption?: string;
  // The width of the menu panel in rems.
  viewportWidth?: number;
  viewportOffset?: number;
  children?: MenuItem[];
}

export const menuItems = (mobile?: boolean): MenuItem[] => {
  return [
  {
    id: "sports",
    label: "Sports",
    heading: "Sports",
    viewportWidth: 15.56,
    viewportOffset: -17,
    children: [
      ...SPORTS.map((sport, i) => ({
        id: sport.id,
        heading: sport.id,
        label: sport.label,
        icon: sport.icon,
        children: [
          {
            id: `overview-${sport.id}`,
            slug: `/${sport.id}`,
            label: "Overview",
            caption: "Games, news, & more",
            icon: "flash",
          },
          {
            id: `scores-${sport.id}`,
            slug: createScoresSlug(sport.id as Sport),
            label: "Scores",
            caption: "Games and scores",
            icon: "scoreboard",
          },
          {
            id: `standings-${sport.id}`,
            slug: createStandingsSlug(sport.id as Sport),
            label: "Standings",
            caption: "Season records",
            icon: "team",
          },
          {
            id: `stats-${sport.id}`,
            slug: createStatsSlug(sport.id as Sport),
            label: "Stats",
            caption: "League statistics",
            icon: "graph-stats-ascend",
          },
          {
            id: `teams-${sport.id}`,
            slug: `/${sport.id}/teams`,
            label: "Teams",
            caption: "All teams",
            icon: "team",
          },
          {
            id: `injuries-${sport.id}`,
            slug: createInjuriesSlug(sport.id as Sport),
            label: "Injuries",
            caption: "Latest injury report",
            icon: "bandage",
          },
          {
            id: `odds-${sport.id}`,
            slug: `/${sport.id}/odds`,
            label: "Odds",
            caption: "Odds and betting lines",
            icon: "plane-board-arrival-departure",
          },

          {
            id: `picks-${sport.id}`,
            slug: `/${sport.id}/picks`,
            label: "Picks",
            caption: "Top pickers and predictions",
            icon: "ticket-buy",
          },
          {
            id: `news-${sport.id}`,
            slug: `/${sport.id}/news`,
            label: "News",
            caption: "Latest news articles",
            icon: "newspaper-fold",
          },
          {
            id: `leaderboard-${sport.id}`,
            slug: `/${sport.id}/leaderboard`,
            label: "Leaderboard",
            caption: "Hottest and coldest pickers",
            icon: "trophy",
          },
        ],
      })),
      {
        id: "coming-soon",
        heading: "coming soon",
        label: "Coming Soon",
        icon: "sparkle",
        children: [
          ...COMING_SOON_SPORTS.map((sport, i) => ({
            label: sport.label,
            icon: sport.icon,
            slug: `/${sport.id}/coming-soon`,
            disabled: true,
          })),
        ],
      },
    ],
  },
  {
    id: "odds",
    label: "Odds",
    heading: "Odds By Sport",
    viewportWidth: 20.5,
    viewportOffset: -13,
    children: [
      ...SPORTS.map((sport, i) => ({
        id: `odds-${sport.id}`,
        slug: `/${sport.id}/odds`,
        label: sport.label,
        icon: sport.icon,
        children:
          i === 0
            ? [
                {
                  id: `odds`,
                  slug: `/odds`,
                  label: "All Odds",
                  caption: "All sports odds",
                  icon: "plane-board-arrival-departure",
                },
                // {
                //   slug: "/betting-guide/faq",
                //   label: "FAQs",
                //   caption: "Get answers",
                //   icon: "user-question",
                // },
                {
                  slug: "/betting-guide/sportsbetting-101/odds",
                  label: "How to Use Odds",
                  caption: "Learn about odds",
                  icon: "graduation-hat",
                },
              ]
            : undefined,
      })),
    ],
  },
  {
    id: "picks",
    label: "Picks",
    heading: "Picks By Sport",
    viewportWidth: 20.5,
    viewportOffset: -10,
    children: [
      ...SPORTS.map((sport, i) => ({
        id: `picks-${sport.id}`,
        slug: `/${sport.id}/picks`,
        label: sport.label,
        icon: sport.icon,
        children:
          i === 0
            ? [
                {
                  id: "all-picks",
                  slug: "/picks",
                  label: "All Picks",
                  caption: "All sports picks",
                  icon: "plane-board-arrival-departure",
                },
                // {
                //   id: "learn-about-picks",
                //   slug: "/picks",
                //   label: "Learn about Picks",
                //   caption: "Picks in sports betting",
                //   icon: "tickets",
                // },
                // {
                //   id: "glossary",
                //   slug: "/betting-guide/glossary",
                //   label: "Betting Terms & Glossary",
                //   caption: "Picks in sports betting",
                //   icon: "glossary",
                // },
                // {
                //   id: "how-moneyline-works",
                //   slug: "/how-moneyline-works",
                //   label: "How Moneyline Works",
                //   caption: "Picks on Moneyline",
                //   icon: "monogram",
                // },
                // {
                //   id: "betting-guide",
                //   slug: "/betting-guide/sportsbetting-101",
                //   label: "Betting Guide",
                //   caption: "League statistics",
                //   icon: "open-book",
                // },
                // {
                //   id: "faq",
                //   slug: "/betting-guide/faq",
                //   label: "FAQs",
                //   caption: "Get answers",
                //   icon: "user-question",
                // },
              ]
            : undefined,
      })),
    ],
  },
  {
    id: "learn",
    label: "Learn",
    heading: "Learn",
    viewportWidth: 20.5,
    viewportOffset: -3,

    children: [
      ...(mobile ? [
        {
          id: "how-moneyline-works",
          slug: "/how-moneyline-works",
          label: "How Moneyline Works",
          caption: "Learn about picks on Moneyline",
          icon: "monogram",
        },
        {
          id: "betting-guide",
          slug: "/betting-guide/sportsbetting-101",
          label: "Betting Guide",
          caption: "Learn how to bet",
          icon: "open-book",
        },
        {
          id: "glossary",
          slug: "/betting-guide/glossary",
          label: "Bettings terms & glossary",
          caption: "Terms and glossary",
          icon: "glossary",
        },
        {
          id: "faq",
          slug: "/betting-guide/faq",
          label: "FAQs",
          caption: "Get answers",
          icon: "user-question",
        },
        {
          id: "legal-states",
          label: "Legal States",
          heading: "Legal States",
          caption: "Legal betting states",
          logo: "usa",
        },
       
      ]: [
        {
          id: "education",
          label: "Education",
          heading: "Resources",
          caption: "Moneyline and Betting Guide",
          logo: "moneyline",
          children: [
            {
              id: "how-moneyline-works",
              slug: "/how-moneyline-works",
              label: "How Moneyline Works",
              caption: "Learn about picks on Moneyline",
              icon: "monogram",
            },
            {
              id: "betting-guide",
              slug: "/betting-guide/sportsbetting-101",
              label: "Betting Guide",
              caption: "Learn how to bet",
              icon: "open-book",
            },
            {
              id: "glossary",
              slug: "/betting-guide/glossary",
              label: "Bettings terms & glossary",
              caption: "Terms and glossary",
              icon: "glossary",
            },
            {
              id: "faq",
              slug: "/betting-guide/faq",
              label: "FAQs",
              caption: "Get answers",
              icon: "user-question",
            },
          ],
        },
        {
          id: "legal-states",
          label: "Legal States",
          heading: "Legal States",
          caption: "Legal betting states",
          logo: "usa",
        },
      ]),
      
      
      
    ],
    
  },
  {
    id: "sportsbooks",
    label: "Sportsbooks",
    heading: "Sportsbooks",
    children: [
      {
        id: "all-sportsbooks",
        slug: "/sportsbooks",
        label: "All Sportsbooks",
        icon: "seo-search",
        caption: "View all sportsbooks",
        heading: "Top Sportsbooks",
        // children: [
        //   {
        //     id: "barstool",
        //     slug: "sportsbooks/barstool",
        //     label: "Barstool",
        //     logo: "barstool",
        //   },
        //   {
        //     id: "fanduel",
        //     slug: "sportsbooks/fanduel",
        //     label: "FanDuel",
        //     logo: "fanduel",
        //   },
        //   {
        //     id: "sportsillustrated",
        //     slug: "sportsbooks/sportsillustrated",
        //     label: "SI",
        //     logo: "sportsillustrated",
        //   },
        //   {
        //     id: "betmgm",
        //     slug: "sportsbooks/betmgm",
        //     label: "BetMGM",
        //     logo: "betmgm",
        //   },
        //   {
        //     id: "parxpa",
        //     slug: "sportsbooks/parxpa",
        //     label: "ParxPA",
        //     logo: "parxpa",
        //   },
        //   {
        //     id: "sugarhouse",
        //     slug: "sportsbooks/sugarhouse",
        //     label: "Sugar House",
        //     logo: "sugarhouse",
        //   },
        //   {
        //     id: "caesars",
        //     slug: "sportsbooks/caesars",
        //     label: "Caesars",
        //     logo: "caesars",
        //   },
        //   {
        //     id: "pointsbet",
        //     slug: "sportsbooks/pointsbet",
        //     label: "PointsBet",
        //     logo: "pointsbet",
        //   },
        //   {
        //     id: "unibet",
        //     slug: "sportsbooks/unibet",
        //     label: "UniBet",
        //     logo: "unibet",
        //   },
        //   {
        //     id: "draftkings",
        //     slug: "sportsbooks/draftkings",
        //     label: "DraftKings",
        //     logo: "draftkings",
        //   },
        //   {
        //     id: "riverscasino",
        //     slug: "sportsbooks/riverscasino",
        //     label: "Rivers",
        //     logo: "riverscasino",
        //   },
        //   {
        //     id: "wynnbet",
        //     slug: "sportsbooks/wynnbet",
        //     label: "WynnBet",
        //     logo: "wynnbet",
        //   },
        // ],
      },
    ],
  },
  {
    id: "leaderboard",
    label: "Leaderboard",
    heading: "Leaderboard By Sport",
    viewportWidth: 20.5,
    viewportOffset: 10,

    children: [
      ...SPORTS.map((sport, i) => ({
        id: `leaderboard-${sport.id}`,
        slug: `/${sport.id}/leaderboard`,
        label: sport.label,
        icon: sport.icon,
        children:
          i === 0
            ? [
                {
                  id: "leaderboard",
                  slug: "/leaderboard",
                  label: "Global Leaderboard",
                  caption: "View all sports leaders",
                  icon: "trophy",
                },
              ]
            : undefined,
      })),
    ],
  },
  
];
}
