import { useState, useEffect } from 'react';
import mlClient from "@/ml-client";

type UseDataLazyloadProps = {
    ref: React.RefObject<HTMLElement>;
    dependencies?: any[];
    url?: string;
};

export const useDataLazyload = ({ ref, url, dependencies = []}: UseDataLazyloadProps) => {
    const [data, setData] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [error, setError] = useState<string | null>(null);

    function uploadData() {
        if (!url) return;

        setIsLoading(true);

        mlClient.get(url).then((res) => {
            setData(res.data);
        }).then(() => {
            setIsLoading(false);
        }).catch((error) => {
            setError(error);
        });
    }

    useEffect(() => {
        if (!ref.current) return;

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            { threshold: 0.1 }
        );

        observer.observe(ref.current);

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [ref]);

    useEffect(() => {
        if (isVisible) uploadData();
    }, [isVisible, url, ...dependencies]);

    return { data, isLoading, isVisible, error };
};
